<template>
  <busy-modal :card="busyCard" />
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import BusyModal from '@/@leecom/busy-modal/BusyModal.vue'

export default {
  components: { BusyModal },
  data() {
    return {
      busyCard: {
        state: 'WAITING',
      },
    }
  },
  created() {
    useJwt
      .logout()
      .then(() => {
        // Remove userData from localStorage

        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'login' })
      })
      .catch(error => {
        // Remove userData from localStorage

        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        this.$router.push({ name: 'login' })
      })
  },
}
</script>

<style></style>
